import React from 'react';
import { Tabs, Card, Typography, Alert, Space } from 'antd';
import CoordinatorCycles from './CoordinatorCycles';
import AutoReassignmentCycles from './AutoReassignmentCycles';

const { Title } = Typography;

const CyclesManagement = () => {
  const items = [
    {
      key: 'np3',
      label: 'NP3 Flow Cycles',
      children: (
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Alert
            message="NP3 Flow Cycles Information"
            description={
              <ul>
                <li>These cycles define the flow of leads when they are marked as NP3</li>
                <li>Leads will automatically move to the next coordinator in sequence</li>
                <li>After the last coordinator, leads will be assigned to the final coordinator</li>
                <li>All reminders and assignments are tracked in history</li>
              </ul>
            }
            type="info"
            showIcon
          />
          <CoordinatorCycles />
        </Space>
      ),
    },
    {
      key: 'auto',
      label: 'Auto Reassignment Cycles',
      children: (
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Alert
            message="Auto Reassignment Information"
            description={
              <ul>
                <li>Leads are automatically reassigned if there's no activity for 6 hours</li>
                <li>Non-Calling hours (8:30 PM IST to 10:00 AM IST) are excluded from the 6-hour count</li>
                <li>Reminders are automatically reassigned with the leads</li>
                <li>Cycles can be activated/deactivated as needed</li>
              </ul>
            }
            type="info"
            showIcon
          />
          <AutoReassignmentCycles />
        </Space>
      ),
    },
  ];

  return (
    <div className="p-6">
      <Card>
        <Title level={2}>Coordinator Cycles Management</Title>
        <Title level={5} type="secondary" className="mb-6">
          Manage NP3 flow cycles and auto reassignment cycles
        </Title>
        <Tabs
          defaultActiveKey="np3"
          items={items}
          type="card"
          size="large"
        />
      </Card>
    </div>
  );
};

export default CyclesManagement;