import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  TimePicker,
  InputNumber,
  message,
  Space,
  Card,
  Tooltip,
  Popconfirm,
  Tag
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  getAssignmentRules,
  createAssignmentRule,
  updateAssignmentRule,
  deleteAssignmentRule,
  resetAssignmentCounts,
  getCoordinators
} from '../apiService';

const { Option } = Select;
const { TextArea } = Input;

const AssignmentRules = () => {
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingRule, setEditingRule] = useState(null);
  const [coordinators, setCoordinators] = useState([]);
  const [form] = Form.useForm();

  const fetchRules = async () => {
    try {
      setLoading(true);
      const data = await getAssignmentRules();
      setRules(data);
    } catch (error) {
      message.error('Failed to fetch assignment rules');
    } finally {
      setLoading(false);
    }
  };

  const fetchCoordinators = async () => {
    try {
      const data = await getCoordinators();
      setCoordinators(data);
    } catch (error) {
      message.error('Failed to fetch coordinators');
    }
  };

  useEffect(() => {
    fetchRules();
    fetchCoordinators();
  }, []);

  const handleAddRule = () => {
    setEditingRule(null);
    form.resetFields();
    setModalVisible(true);
  };

  const handleEditRule = (rule) => {
    setEditingRule(rule);
    form.setFieldsValue({
      ...rule,
      demo_time: rule.demo_time ? dayjs(rule.demo_time, 'HH:mm:ss') : undefined,
    });
    setModalVisible(true);
  };

  const handleDeleteRule = async (ruleId) => {
    try {
      await deleteAssignmentRule(ruleId);
      message.success('Rule deleted successfully');
      fetchRules();
    } catch (error) {
      message.error('Failed to delete rule');
    }
  };

  const handleResetCounts = async () => {
    try {
      await resetAssignmentCounts();
      message.success('Assignment counts reset successfully');
      fetchRules();
    } catch (error) {
      message.error('Failed to reset assignment counts');
    }
  };

  const handleSubmit = async (values) => {
    try {
      const formattedValues = {
        ...values,
        demo_time: values.demo_time ? values.demo_time.format('HH:mm:ss') : undefined,
        course_keywords: values.course_keywords ? values.course_keywords.trim() : null
      };

      if (editingRule) {
        await updateAssignmentRule(editingRule.id, formattedValues);
        message.success('Rule updated successfully');
      } else {
        await createAssignmentRule(formattedValues);
        message.success('Rule created successfully');
      }

      setModalVisible(false);
      fetchRules();
    } catch (error) {
      message.error('Failed to save rule');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Demo Time',
      dataIndex: 'demo_time',
      key: 'demo_time',
    },
    {
      title: 'Course Keywords',
      dataIndex: 'course_keywords',
      key: 'course_keywords',
      render: keywords => {
        if (!keywords) return '-';
        return keywords.split(',').map(keyword => (
          <Tag key={keyword.trim()} color="blue">{keyword.trim()}</Tag>
        ));
      }
    },
    {
      title: 'Assignment Type',
      dataIndex: 'assignment_type',
      key: 'assignment_type',
      render: (text) => text.replace('_', ' ').toUpperCase(),
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      sorter: (a, b) => a.priority - b.priority,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              onClick={() => handleEditRule(record)}
              type="text"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure you want to delete this rule?"
              onConfirm={() => handleDeleteRule(record.id)}
            >
              <Button icon={<DeleteOutlined />} type="text" danger />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div className="p-6">
      <Card
        title="Lead Assignment Rules"
        extra={
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAddRule}
            >
              Add Rule
            </Button>
            <Tooltip title="Reset Assignment Counts">
              <Button
                icon={<ReloadOutlined />}
                onClick={handleResetCounts}
              >
                Reset Counts
              </Button>
            </Tooltip>
          </Space>
        }
      >
        <Table
          columns={columns}
          dataSource={rules}
          rowKey="id"
          loading={loading}
        />
      </Card>

      <Modal
        title={editingRule ? 'Edit Rule' : 'Add Rule'}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="name"
            label="Rule Name"
            rules={[{ required: true, message: 'Please enter rule name' }]}
          >
            <Input placeholder="Enter rule name" />
          </Form.Item>

          <Form.Item
            name="status"
            label="Lead Status"
          >
            <Select allowClear placeholder="Select lead status">
              <Option value="Demo Booked">Demo Booked</Option>
              <Option value="Unconfirmed">Unconfirmed</Option>
              <Option value="New">New</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="demo_time"
            label="Demo Time"
          >
            <TimePicker format="HH:mm" placeholder="Select demo time" />
          </Form.Item>

          <Form.Item
            name="course_keywords"
            label="Course Keywords"
            tooltip="Enter comma-separated keywords. The rule will match leads whose course name contains any of these keywords."
          >
            <TextArea
              placeholder="E.g., python, javascript, data science"
              autoSize={{ minRows: 2, maxRows: 4 }}
            />
          </Form.Item>

          <Form.Item
            name="assignment_type"
            label="Assignment Type"
            rules={[{ required: true, message: 'Please select assignment type' }]}
          >
            <Select placeholder="Select assignment type">
              <Option value="single">Single Coordinator</Option>
              <Option value="round_robin">Round Robin</Option>
              <Option value="ratio">Ratio Based</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="priority"
            label="Priority"
            rules={[{ required: true, message: 'Please enter priority' }]}
            tooltip="Higher priority rules are evaluated first"
          >
            <InputNumber min={0} placeholder="Enter priority" />
          </Form.Item>

          <Form.List
            name="coordinators"
            rules={[
              {
                validator: async (_, coordinators) => {
                  if (!coordinators || coordinators.length < 1) {
                    return Promise.reject(new Error('At least one coordinator is required'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Space key={field.key} align="baseline">
                    <Form.Item
                      {...field}
                      label={index === 0 ? "Coordinators" : ""}
                      name={[field.name, 'id']}
                      rules={[{ required: true, message: 'Please select coordinator' }]}
                    >
                      <Select style={{ width: 200 }} placeholder="Select coordinator">
                        {coordinators.map(coord => (
                          <Option key={coord.id} value={coord.id}>
                            {coord.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues?.assignment_type !== curValues?.assignment_type
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue('assignment_type') === 'ratio' && (
                          <Form.Item
                            {...field}
                            name={[field.name, 'ratio']}
                            rules={[{ required: true, message: 'Please enter ratio' }]}
                          >
                            <InputNumber min={1} placeholder="Ratio" />
                          </Form.Item>
                        )
                      }
                    </Form.Item>

                    {fields.length > 1 && (
                      <Button type="text" danger onClick={() => remove(field.name)}>
                        Delete
                      </Button>
                    )}
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Coordinator
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {editingRule ? 'Update' : 'Create'}
              </Button>
              <Button onClick={() => setModalVisible(false)}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AssignmentRules;