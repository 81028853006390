import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Input, Button, List, Spin, Empty, Tooltip, Badge, Modal } from 'antd';
import { 
  UserOutlined, 
  SendOutlined, 
  CloseOutlined,
  PaperClipOutlined,
  CheckOutlined,
  MinusOutlined,
  UserAddOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { format } from 'date-fns';
import {
  getPrivateConversations,
  getPrivateMessages,
  sendPrivateMessage,
  markPrivateMessagesAsRead,
} from '../apiService';

// User Selector Component
const UserSelector = ({ visible, onClose, onSelectUser, excludeIds = [] }) => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      fetchUsers();
    }
  }, [visible]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await fetch('https://it.nilconnect.in/wp-json/ei-crm/v1/users', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      setUsers(data.users.filter(user => !excludeIds.includes(user.id)));
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredUsers = users.filter(user => 
    user.display_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal
      title="Start New Conversation"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={400}
      className="bg-gray-900 text-white"
    >
      <div className="space-y-4">
        <Input
          prefix={<SearchOutlined className="text-gray-400" />}
          placeholder="Search users..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className="mb-4 bg-gray-800 text-white border-gray-700"
        />
        <List
          loading={loading}
          dataSource={filteredUsers}
          renderItem={user => (
            <List.Item 
              className="cursor-pointer hover:bg-gray-800 transition-colors text-white"
              onClick={() => onSelectUser(user)}
            >
              <List.Item.Meta
                avatar={<Avatar src={user.avatar_url} icon={<UserOutlined />} />}
                title={<span className="text-white">{user.display_name}</span>}
                description={<span className="text-gray-400">{user.email}</span>}
              />
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

const PrivateMessagesPanel = ({ visible, onClose, initialUser = null }) => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [minimized, setMinimized] = useState(false);
  const [userSelectorVisible, setUserSelectorVisible] = useState(false);
  const [animationPhase, setAnimationPhase] = useState('initial');
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const panelRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const mergeMessages = (existingMessages, newMessages) => {
    const messageMap = new Map(existingMessages.map(msg => [msg.id, msg]));
    newMessages.forEach(msg => {
      if (!messageMap.has(msg.id)) {
        messageMap.set(msg.id, msg);
      }
    });
    return Array.from(messageMap.values())
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  };
  
  const mergeConversations = (existingConvs, newConvs) => {
    const convMap = new Map(existingConvs.map(conv => [conv.id, conv]));
    let hasChanges = false;
  
    newConvs.forEach(newConv => {
      const existingConv = convMap.get(newConv.id);
      if (!existingConv || 
          existingConv.last_message_time !== newConv.last_message_time ||
          existingConv.unread_count !== newConv.unread_count) {
        convMap.set(newConv.id, newConv);
        hasChanges = true;
      }
    });
  
    if (!hasChanges) return existingConvs;
  
    return Array.from(convMap.values())
      .sort((a, b) => {
        if (a.unread_count !== b.unread_count) {
          return b.unread_count - a.unread_count;
        }
        return new Date(b.last_message_time) - new Date(a.last_message_time);
    });
  };

  const handleStartNewConversation = async (user) => {
    if (!user || !user.id) return;
    
    setUserSelectorVisible(false);
    
    try {
      // First check if conversation already exists in current conversations
      const existingConversation = conversations.find(
        conv => conv.other_participant_id === user.id.toString()
      );
      
      if (existingConversation) {
        setSelectedConversation(existingConversation);
        await fetchMessages(existingConversation.id);
        return;
      }
      
      // If not found in current conversations, fetch all conversations to ensure we have latest data
      await fetchConversations();
      
      // Check again after fetching fresh data
      const freshExistingConversation = conversations.find(
        conv => conv.other_participant_id === user.id.toString()
      );
      
      if (freshExistingConversation) {
        setSelectedConversation(freshExistingConversation);
        await fetchMessages(freshExistingConversation.id);
        return;
      }
  
      // If still no existing conversation, create a new one
      const newConversation = {
        id: `temp_${Date.now()}`,
        other_participant_id: user.id.toString(),
        other_participant_name: user.display_name,
        other_participant_profile_image_url: user.avatar_url || user.profile_image,
        last_message: '',
        unread_count: 0,
        last_message_time: new Date().toISOString()
      };
      
      setSelectedConversation(newConversation);
      setMessages([]);
      setConversations(prev => [newConversation, ...prev]);
    } catch (error) {
      console.error('Error starting new conversation:', error);
    }
  };

  const fetchConversations = async () => {
    try {
      const data = await getPrivateConversations();
      const sortedConversations = data.sort((a, b) => {
        if (a.unread_count !== b.unread_count) {
          return b.unread_count - a.unread_count;
        }
        return new Date(b.last_message_time) - new Date(a.last_message_time);
      });
      setConversations(sortedConversations);

      if (!selectedConversation && sortedConversations.length > 0) {
        const firstUnread = sortedConversations.find(c => c.unread_count > 0) || sortedConversations[0];
        setSelectedConversation(firstUnread);
        fetchMessages(firstUnread.id);
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  const fetchMessages = async (conversationId) => {
    setLoading(true);
    try {
      // If it's a temporary conversation ID, don't try to fetch messages
      if (conversationId.startsWith('temp_')) {
        setMessages([]);
        setLoading(false);
        return;
      }
  
      const data = await getPrivateMessages(conversationId);
      const sortedMessages = data.sort((a, b) => 
        new Date(a.created_at) - new Date(b.created_at)
      );
      setMessages(sortedMessages);
      
      // Only mark as read if it's a real conversation
      if (!conversationId.startsWith('temp_')) {
        await markPrivateMessagesAsRead(conversationId);
      }
      
      scrollToBottom();
    } catch (error) {
      console.error('Error fetching messages:', error);
      // If error occurs, set empty messages to prevent loading state
      setMessages([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserAndStartConversation = async (userId) => {
    if (!userId) return;
    
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await fetch(`https://it.nilconnect.in/wp-json/ei-crm/v1/users/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch user');
      }
      
      const userData = await response.json();
      
      const user = {
        id: userId.toString(),
        display_name: userData.display_name,
        avatar_url: userData.profile_image,
        email: userData.email
      };
      
      await handleStartNewConversation(user);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  useEffect(() => {
    if (visible) {
      setAnimationPhase('entering');
      requestAnimationFrame(() => {
        setAnimationPhase('entered');
      });
      
      fetchConversations().then(() => {
        if (initialUser) {
          const formattedUser = {
            id: initialUser.id.toString(),
            display_name: initialUser.display_name,
            avatar_url: initialUser.profile_image,
            email: initialUser.email || ''
          };
          handleStartNewConversation(formattedUser);
        }
      });
    } else {
      setAnimationPhase('exiting');
      const timer = setTimeout(() => {
        setAnimationPhase('exited');
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [visible, initialUser]);

  useEffect(() => {
    if (initialUser) {
      fetchUserAndStartConversation(initialUser);
    }
  }, [initialUser]);

  useEffect(() => {
    let interval;
    if (visible) {
      interval = setInterval(async () => {
        try {
          const newConversations = await getPrivateConversations();
          setConversations(prevConvs => mergeConversations(prevConvs, newConversations));

          if (selectedConversation) {
            const newMessages = await getPrivateMessages(selectedConversation.id);
            setMessages(prevMsgs => {
              const merged = mergeMessages(prevMsgs, newMessages);
              if (merged.length > prevMsgs.length) {
                setTimeout(scrollToBottom, 100);
              }
              return merged;
            });
          }
        } catch (error) {
          console.error('Error polling messages:', error);
        }
      }, 10000);
    }

    return () => clearInterval(interval);
  }, [visible, selectedConversation?.id]);

  const handleConversationSelect = (conversation) => {
    setSelectedConversation(conversation);
    fetchMessages(conversation.id);
  };

  const handleSendMessage = async () => {
    if ((!messageText.trim() && !selectedFile) || !selectedConversation) return;
  
    setSending(true);
    try {
      const response = await sendPrivateMessage(
        selectedConversation.other_participant_id,
        messageText,
        selectedFile
      );
  
      // If this was a temporary conversation, update it with the real conversation data
      if (selectedConversation.id.startsWith('temp_')) {
        const newConversations = await getPrivateConversations();
        const realConversation = newConversations.find(
          conv => conv.other_participant_id === selectedConversation.other_participant_id
        );
        
        if (realConversation) {
          setSelectedConversation(realConversation);
          setConversations(prev => 
            prev.map(conv => 
              conv.id === selectedConversation.id ? realConversation : conv
            )
          );
          await fetchMessages(realConversation.id);
        }
      } else {
        await fetchMessages(selectedConversation.id);
      }
  
      setMessageText('');
      setSelectedFile(null);
      await fetchConversations();
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setSending(false);
    }
  };

  const handleClose = () => {
    setAnimationPhase('exiting');
    setTimeout(() => {
      onClose();
      setAnimationPhase('exited');
    }, 300);
  };

  const handleMinimize = () => {
    setMinimized(!minimized);
  };

  if (!visible && animationPhase === 'exited') return null;

  const panelClasses = `
    fixed left-0 bg-gray-900 shadow-lg
    border-l border-b border-gray-700 z-[999]
    transition-all duration-300 ease-in-out transform
    w-full md:w-1/2
    ${animationPhase === 'entering' ? 'translate-x-full opacity-0' : ''}
    ${animationPhase === 'entered' ? 'translate-x-0 opacity-100' : ''}
    ${animationPhase === 'exiting' ? 'translate-y-[-100%] opacity-0' : ''}
    ${minimized ? 'h-12' : 'h-[600px]'}
  `;

  const contentClasses = `
    transition-all duration-300 ease-in-out overflow-hidden
    ${minimized ? 'h-0 opacity-0' : 'h-[calc(100%-48px)] opacity-100'}
  `;

  return (
    <>
      <div 
        ref={panelRef}
        className={panelClasses}
        style={{
          top: '104px',
          transformOrigin: 'top left',
        }}
      >
        {/* Header */}
        <div className="h-12 border-b border-gray-700 flex items-center justify-between px-4 bg-gray-800">
          <div className="flex items-center gap-2">
            <h3 className="text-lg font-medium text-white">
              {selectedConversation ? (
                <div className="flex items-center gap-2">
                  <Avatar 
                    size="small" 
                    src={selectedConversation.other_participant_profile_image_url}
                    icon={<UserOutlined />}
                  />
                  <span>{selectedConversation.other_participant_name}</span>
                </div>
              ) : (
                <span>Messages</span>
              )}
            </h3>
          </div>
          <div className="flex gap-2">
            <Button
              type="text"
              icon={minimized ? <SendOutlined rotate={-90} /> : <MinusOutlined />}
              onClick={handleMinimize}
              className="text-white transition-transform duration-300"
            />
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={handleClose}
              className="text-white"
            />
          </div>
        </div>

        <div className={contentClasses}>
          <div className="flex h-full">
            {/* Conversations List */}
            <div className="w-1/3 border-r border-gray-700 flex flex-col">
              <div className="p-2 border-b border-gray-700">
                <Button 
                  type="primary"
                  icon={<UserAddOutlined />}
                  onClick={() => setUserSelectorVisible(true)}
                  className="w-full"
                >
                  New Message
                </Button>
              </div>
              <div className="overflow-y-auto flex-1">
                <List
                  dataSource={conversations}
                  renderItem={(conversation) => (
                    <List.Item
                      className={`cursor-pointer hover:bg-gray-800 transition-colors
                        ${selectedConversation?.id === conversation.id 
                          ? 'bg-gray-700' 
                          : ''}`}
                      onClick={() => handleConversationSelect(conversation)}
                      style={{ paddingLeft: '30px' }}
                    >
                      <List.Item.Meta
                        avatar={
                          <Badge dot={conversation.unread_count > 0}>
                            <Avatar
                              src={conversation.other_participant_profile_image_url}
                              icon={<UserOutlined />}
                            />
                          </Badge>
                        }
                        title={<span className="text-white">
                          {conversation.other_participant_name}
                        </span>}
                        description={
                          <div className="text-xs text-gray-400 truncate">
                            {conversation.last_message}
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
            </div>

            {/* Messages Area */}
            <div className="flex-1 flex flex-col">
              {selectedConversation ? (
                <>
                  {/* Messages List */}
                  <div className="flex-1 overflow-y-auto p-4 bg-gray-900">
                    {loading ? (
                      <div className="flex justify-center items-center h-full">
                        <Spin />
                      </div>
                    ) : (
                      <div className="space-y-4">
                        {messages.map((message) => (
                          <div
                            key={message.id}
                            className={`flex ${
                              message.sender_id === selectedConversation.other_participant_id
                                ? 'justify-start'
                                : 'justify-end'
                            }`}
                          >
                            <div
                              className={`max-w-[70%] rounded-lg p-3 ${
                                message.sender_id === selectedConversation.other_participant_id
                                  ? 'bg-gray-800 text-white'
                                  : 'bg-blue-500 text-white'
                              }`}
                            >
                              <div>
                                <div className="mb-1">{message.message}</div>
                                {message.media_url && (
                                  <div className="mt-2">
                                    {message.media_type?.startsWith('image/') ? (
                                      <img 
                                        src={message.media_url} 
                                        alt="Attachment" 
                                        className="max-w-[200px] rounded-lg"
                                      />
                                    ) : (
                                      <a 
                                        href={message.media_url} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className="text-blue-400 hover:text-blue-300"
                                      >
                                        View Attachment
                                      </a>
                                    )}
                                  </div>
                                )}
                                <div className="text-xs text-gray-400 mt-1">
                                  {formatMessageTime(message.created_at)}
                                  {message.is_read && (
                                    <Tooltip title="Read">
                                      <CheckOutlined className="ml-2 text-green-500" />
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div ref={messagesEndRef} />
                      </div>
                    )}
                  </div>

                  {/* Message Input */}
                  <div className="p-4 border-t border-gray-700 bg-gray-800">
                    {selectedFile && (
                      <div className="mb-2 text-sm text-gray-400">
                        Selected file: {selectedFile.name}
                        <Button
                          type="text"
                          size="small"
                          onClick={() => setSelectedFile(null)}
                          icon={<CloseOutlined />}
                          className="text-white"
                        />
                      </div>
                    )}
                    <div className="flex gap-2">
                      <Input
                        value={messageText}
                        onChange={(e) => setMessageText(e.target.value)}
                        onPressEnter={handleSendMessage}
                        placeholder="Type a message..."
                        disabled={sending}
                        className="bg-gray-700 text-white border-gray-600"
                      />
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file && file.size <= 10 * 1024 * 1024) {
                            setSelectedFile(file);
                          } else {
                            alert('File size must be less than 10MB');
                            e.target.value = null;
                          }
                        }}
                        className="hidden"
                        accept="image/*,.pdf,.doc,.docx,.txt"
                      />
                      <Button
                        icon={<PaperClipOutlined />}
                        onClick={() => fileInputRef.current?.click()}
                        disabled={sending}
                        className="text-white"
                      />
                      <Button
                        type="primary"
                        icon={<SendOutlined />}
                        onClick={handleSendMessage}
                        loading={sending}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex items-center justify-center h-full bg-gray-900">
                  <Empty 
                    description={
                      <span className="text-gray-400">
                        Select a conversation
                      </span>
                    }
                    image={Empty.PRESENTED_IMAGE_SIMPLE} 
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      <div className="z-[1000]">
        <UserSelector
          visible={userSelectorVisible}
          onClose={() => setUserSelectorVisible(false)}
          onSelectUser={handleStartNewConversation}
          excludeIds={conversations.map(c => c.other_participant_id)}
        />
      </div>
    </>
  );
};

// Helper function to format message time
const formatMessageTime = (timestamp) => {
  const messageDate = new Date(timestamp);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (messageDate.toDateString() === today.toDateString()) {
    return format(messageDate, 'HH:mm');
  } else if (messageDate.toDateString() === yesterday.toDateString()) {
    return 'Yesterday ' + format(messageDate, 'HH:mm');
  } else {
    return format(messageDate, 'dd MMM HH:mm');
  }
};

export default PrivateMessagesPanel;