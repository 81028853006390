import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { ArrowUp } from 'lucide-react';

// Custom hook to track previous value
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const NewMessagesNotification = ({ unreadCount }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const prevUnreadCount = usePrevious(unreadCount);

  useEffect(() => {
    // Show notification when unread count increases
    if (unreadCount > (prevUnreadCount || 0)) {
      setIsVisible(true);
      updatePosition();
    }
    // Hide notification when unread count decreases (messages were read)
    else if (unreadCount < prevUnreadCount) {
      setIsVisible(false);
    }
  }, [unreadCount, prevUnreadCount]);

  useEffect(() => {
    // Hide tooltip after 5 seconds if it's visible
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  // Update position when window is resized
  useEffect(() => {
    if (isVisible) {
      window.addEventListener('resize', updatePosition);
      window.addEventListener('scroll', updatePosition, true);
      return () => {
        window.removeEventListener('resize', updatePosition);
        window.removeEventListener('scroll', updatePosition, true);
      };
    }
  }, [isVisible]);

  const updatePosition = () => {
    const messageButton = document.querySelector('.message-trigger');
    if (messageButton) {
      const rect = messageButton.getBoundingClientRect();
      setPosition({
        top: rect.bottom + 12,
        left: rect.left + (rect.width / 2) - 75,
      });
    }
  };

  if (!isVisible || unreadCount === 0) return null;

  // Render the tooltip using a portal
  return createPortal(
    <div 
      className="fixed z-[9999] pointer-events-none"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
      }}
    >
      <div 
        className={`
          bg-red-500 text-white px-4 py-2 rounded-md text-sm font-medium
          animate-bounce shadow-lg whitespace-nowrap
          relative flex items-center gap-2
          before:content-[''] before:absolute before:bottom-full before:left-1/2
          before:-translate-x-1/2 before:border-8 before:border-transparent
          before:border-b-red-500
        `}
      >
        <ArrowUp className="w-4 h-4" />
        You have new messages
      </div>
    </div>,
    document.body
  );
};

export default NewMessagesNotification;