import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Menu, Dropdown, Avatar, Layout, Drawer } from 'antd';
import { 
  UserOutlined, 
  SettingOutlined, 
  LogoutOutlined, 
  DashboardOutlined, 
  BookOutlined, 
  BarChartOutlined,
  MenuOutlined,
  BellOutlined,
  CloseOutlined,
  EllipsisOutlined
} from '@ant-design/icons';
import Notifications from './Notifications';

const { Header } = Layout;

const Navbar = ({ logout, currentUser, fullyAuthenticated }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const navigate = useNavigate();

  // Split navigation items into main and more dropdown
  const mainNavItems = [
    { key: 'dashboard', label: 'Dashboard', icon: <DashboardOutlined />, link: '/dashboard' },
    { key: 'social', label: 'InConnect', link: '/social' },
    { key: 'leads', label: 'Leads', link: '/leads' },
    { key: 'reminders', label: 'Reminders', link: '/reminders' },
  ];

  const moreNavItems = [
    { key: 'tasks', label: 'Tasks', link: '/tasks' },
    { key: 'canned-responses', label: 'Canned Responses', link: '/canned-responses' },
    { key: 'reports', label: 'Reports', icon: <BarChartOutlined />, link: '/reports' },
    { key: 'wiki', label: 'Wiki', icon: <BookOutlined />, link: '/wiki' },
    {
      key: 'coordinator-cycles',
      label: 'Coordinator Cycles',
      link: '/cycles',
      // Only show for administrators
      hidden: !currentUser?.role?.includes('administrator')
    },
    {
      key: 'assignment-rules',
      label: 'Assignment Rules',
      link: '/assignment-rules',
      hidden: !currentUser?.role?.includes('administrator')
    }
  ];

  // Combined items for mobile view
  const allNavItems = [...mainNavItems, ...moreNavItems];

  const userMenuItems = [
    {
      key: 'profile',
      icon: <UserOutlined />,
      label: 'View Profile',
    },
    {
      key: 'settings',
      icon: <SettingOutlined />,
      label: 'Settings',
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Logout',
    },
  ];

  const handleMenuClick = useCallback((e) => {
    if (e.key === 'logout') {
      logout();
    } else if (e.key === 'profile') {
      navigate(`/user/${currentUser.user_id}`);
    }
    setUserMenuOpen(false);
  }, [logout, navigate, currentUser]);

  const handleNavigation = (link) => {
    navigate(link);
    setDrawerVisible(false);
  };

  return (
    <>
      {/* Main Navbar */}
      <Header className="fixed top-0 left-0 right-0 z-50 flex items-center justify-between bg-white shadow-md px-4 h-16">
        {/* Left section */}
        <div className="flex items-center">
          <button 
            className="lg:hidden mr-4 p-2 hover:bg-gray-100 rounded-full"
            onClick={() => setDrawerVisible(true)}
          >
            <MenuOutlined className="text-xl" />
          </button>
          <img 
            src="https://nationalinstituteoflanguage.in/wp-content/uploads/2024/09/InConnect-1.png" 
            alt="InConnect Logo" 
            className="h-8 cursor-pointer"
            onClick={() => navigate('/')}
          />
        </div>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex flex-1 justify-center items-center">
          {/* Main nav items */}
          {mainNavItems.map(item => (
            <button
              key={item.key}
              onClick={() => handleNavigation(item.link)}
              className="px-4 py-2 mx-1 hover:text-blue-600 transition-colors"
            >
              {item.icon && React.cloneElement(item.icon, { className: 'mr-1' })}
              <span>{item.label}</span>
            </button>
          ))}

          {/* More Dropdown */}
          <Dropdown
            menu={{
              items: moreNavItems.map(item => ({
                key: item.key,
                icon: item.icon,
                label: item.label,
                onClick: () => handleNavigation(item.link),
              })),
            }}
            trigger={['click']}
          >
            <button className="px-4 py-2 mx-1 hover:text-blue-600 transition-colors flex items-center">
              <EllipsisOutlined className="mr-1" />
              <span>More</span>
            </button>
          </Dropdown>
        </div>

        {/* Right section */}
        <div className="flex items-center">
          <div className="mr-4">
            <Notifications fullyAuthenticated={fullyAuthenticated} />
          </div>
          <Dropdown 
            menu={{ items: userMenuItems, onClick: handleMenuClick }}
            open={userMenuOpen}
            onOpenChange={setUserMenuOpen}
            trigger={['click']}
          >
            <div className="flex items-center cursor-pointer">
              <Avatar 
                src={currentUser.profile_image_url} 
                icon={<UserOutlined />}
                size="small"
              />
              <span className="ml-2 hidden md:inline">
                {currentUser.display_name || 'User'}
              </span>
            </div>
          </Dropdown>
        </div>
      </Header>

      {/* Mobile Drawer */}
      <Drawer
        title={
          <div className="flex items-center justify-between">
            <span>Menu</span>
            <button 
              onClick={() => setDrawerVisible(false)}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <CloseOutlined />
            </button>
          </div>
        }
        placement="left"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        width={280}
        closeIcon={null}
      >
        {/* Mobile User Profile Section */}
        <div className="flex items-center p-4 mb-4 bg-gray-50 rounded-lg">
          <Avatar 
            src={currentUser.profile_image_url} 
            icon={<UserOutlined />}
            size="large"
          />
          <div className="ml-3">
            <div className="font-medium">{currentUser.display_name || 'User'}</div>
            <div className="text-sm text-gray-500">View Profile</div>
          </div>
        </div>

        {/* Mobile Navigation Items - All items visible */}
        <Menu
          mode="vertical"
          className="border-none"
          items={allNavItems.map(item => ({
            key: item.key,
            icon: item.icon,
            label: item.label,
            onClick: () => handleNavigation(item.link),
          }))}
        />

        {/* Mobile User Menu Items */}
        <div className="absolute bottom-0 left-0 right-0 border-t">
          <Menu
            mode="vertical"
            className="border-none"
            items={userMenuItems}
            onClick={handleMenuClick}
          />
        </div>
      </Drawer>

      {/* Spacer for fixed header */}
      <div className="h-3" />
    </>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    display_name: PropTypes.string,
    profile_image_url: PropTypes.string,
  }).isRequired,
  fullyAuthenticated: PropTypes.bool.isRequired,
};

export default React.memo(Navbar);