import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Avatar, Tooltip, Skeleton, Badge, Button } from 'antd';
import { UserOutlined, MessageOutlined, InboxOutlined } from '@ant-design/icons';
import { getPrivateUnreadCount } from '../apiService';
import PrivateMessagesPanel from './PrivateMessagesPanel';
import AnimatedTooltip from './AnimatedTooltip';
import NewMessagesNotification from './NewMessagesNotification';

const OnlineUsersBar = () => {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);
  const [messagesPanelVisible, setMessagesPanelVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const panelRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(true);
  
  const handleTooltipClose = useCallback(() => {
    console.log('Closing tooltip');
    setShowTooltip(false);
    localStorage.setItem('messageTooltipShown', 'true');
  }, []);

  // Handle clicks outside the panel
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!messagesPanelVisible) return;

      // Check if the click is inside any of these protected areas
      const isClickInPanel = panelRef.current?.contains(event.target);
      const isClickInTrigger = event.target.closest('.message-trigger');
      const isClickInModal = event.target.closest('.ant-modal-root');
      const isClickInModalMask = event.target.closest('.ant-modal-mask');
      
      // Only close if the click is outside all protected areas
      if (!isClickInPanel && !isClickInTrigger && !isClickInModal && !isClickInModalMask) {
        setMessagesPanelVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [messagesPanelVisible]);

  const getProfileImage = (user) => {
    return user.profile_image || null;
  };

  const fetchOnlineUsers = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        console.error('No authentication token found');
        return;
      }

      const response = await fetch('https://it.nilconnect.in/wp-json/ei-crm/v1/chat/online-users', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      
      if (Array.isArray(data)) {
        const processedUsers = data.map(user => ({
          ...user,
          profile_image: getProfileImage(user)
        }));
        setOnlineUsers(processedUsers);
      }
    } catch (error) {
      console.error('Error fetching online users:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUnreadCount = async () => {
    try {
      const count = await getPrivateUnreadCount();
      setUnreadCount(count);
    } catch (error) {
      console.error('Error fetching unread count:', error);
    }
  };

  const pollMessages = useCallback(async () => {
    try {
      await fetchUnreadCount();
    } catch (error) {
      console.error('Error polling messages:', error);
    }
  }, []);

  useEffect(() => {
    fetchOnlineUsers();
    fetchUnreadCount();
    
    const usersInterval = setInterval(fetchOnlineUsers, 30000);
    const messagesInterval = setInterval(pollMessages, 10000);
    
    return () => {
      clearInterval(usersInterval);
      clearInterval(messagesInterval);
    };
  }, [pollMessages]);

  const handleStartChat = (user) => {
    console.log('Starting chat with user:', user);
    setMessagesPanelVisible(true);
    setSelectedUser(user);
  };

  const toggleMessagesPanel = () => {
    setSelectedUser(null);
    setMessagesPanelVisible(!messagesPanelVisible);
  };

  return (
    <>
      {showTooltip && <AnimatedTooltip onClose={handleTooltipClose} />}
      <div className="fixed top-[64px] left-0 right-0 bg-gray-900 border-b border-gray-800 py-1 px-4 flex items-center z-40 shadow-md min-h-[40px]">
        <div className="flex overflow-x-auto gap-3 pb-1 flex-1 scrollbar-thin scrollbar-track-gray-800 scrollbar-thumb-gray-600">
          <div>
            <Button
              type="text"
              className="text-white hover:text-blue-300 flex items-center gap-2 message-trigger font-semibold"
              icon={<InboxOutlined className="text-white" />}
              onClick={toggleMessagesPanel}
              style={{
                color: 'white'
              }}
            >
              <Badge count={unreadCount} offset={[8, 0]}>
                <span className="text-white">Messages</span>
              </Badge>
            </Button>
            <NewMessagesNotification unreadCount={unreadCount} />
          </div>

          {loading ? (
            <LoadingSkeleton />
          ) : (
            <>
              {Array.isArray(onlineUsers) && onlineUsers.map(user => (
                <UserItem 
                  key={user.id} 
                  user={user} 
                  onMessageClick={handleStartChat}
                />
              ))}
            </>
          )}
        </div>
      </div>

      <div ref={panelRef}>
        <PrivateMessagesPanel
          visible={messagesPanelVisible}
          onClose={() => {
            setMessagesPanelVisible(false);
            setSelectedUser(null);
          }}
          initialUser={selectedUser}
        />
      </div>
    </>
  );
};

const UserItem = ({ user, onMessageClick }) => {
  const handleMessageClick = (e) => {
    e.stopPropagation();
    onMessageClick(user);
  };

  return (
    <div className="relative flex-shrink-0 flex items-center gap-2">
      <Tooltip
        title={
          <div className="text-white">
            <div className="font-medium">{user.display_name}</div>
            <div className="text-xs text-gray-300">
              {isUserActive(user.last_active)
                ? 'Active now'
                : `Last seen ${formatLastSeen(user.last_active)}`
              }
            </div>
          </div>
        }
        placement="bottom"
      >
        <div className="relative cursor-pointer group">
          <Avatar
            src={user.profile_image}
            icon={<UserOutlined />}
            size={24}
            className="shadow-sm"
            onClick={handleMessageClick}
          />
          <OnlineStatus isOnline={isUserActive(user.last_active)} />
          <Button
            type="text"
            size="small"
            icon={<MessageOutlined className="text-white" />}
            className="absolute -right-2 -bottom-2 opacity-0 group-hover:opacity-100 transition-opacity bg-blue-500 hover:bg-blue-600 rounded-full p-1 h-5 w-5 flex items-center justify-center border-0"
            onClick={handleMessageClick}
            style={{
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          />
        </div>
      </Tooltip>
      <div className="text-xs text-gray-400 max-w-[80px] overflow-hidden text-ellipsis whitespace-nowrap">
        {user.display_name}
      </div>
    </div>
  );
};

const OnlineStatus = ({ isOnline }) => (
  <div className={`absolute bottom-0 right-0 w-2 h-2 rounded-full 
                   border-2 border-gray-900 shadow-sm
                   ${isOnline ? 'bg-green-500' : 'bg-gray-500'}`}>
  </div>
);

const LoadingSkeleton = () => (
  <div className="flex items-center gap-4">
    {[1, 2, 3, 4].map(key => (
      <div key={key} className="relative flex-shrink-0 flex items-center gap-2">
        <Skeleton.Avatar active size={24} />
        <Skeleton.Button active size="small" style={{ width: 40, height: 12 }} />
      </div>
    ))}
  </div>
);

const isUserActive = (lastActive) => {
  if (!lastActive) return false;
  const lastActiveTime = new Date(lastActive);
  return (Date.now() - lastActiveTime.getTime()) < 1 * 60 * 1000;
};

const formatLastSeen = (lastActive) => {
  const lastActiveTime = new Date(lastActive);
  const diffMinutes = Math.floor((Date.now() - lastActiveTime.getTime()) / 60000);
  
  if (diffMinutes < 1) return 'Just now';
  if (diffMinutes < 60) return `${diffMinutes}m ago`;
  if (diffMinutes < 1440) return lastActiveTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  return lastActiveTime.toLocaleDateString();
};

export default OnlineUsersBar;