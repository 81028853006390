import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Leads from './components/Leads';
import TaskList from './components/TaskList';
import CannedResponses from './components/CannedResponses';
import Reminders from './components/Reminders';
import Navbar from './components/Navbar';
import UserNotes from './components/UserNotes';
import Wiki from './components/Wiki';
import SocialUpdates from './components/SocialUpdates';
import UserProfile from './components/UserProfile';
import GlobalChat from './components/GlobalChat';
import ReminderPopup from './components/ReminderPopup';
import Reports from './components/Reports';
import QuotePopup from './components/QuotePopup';
import CoordinatorCycles from './components/CoordinatorCycles';
import AssignmentRules from './components/AssignmentRules';
import CyclesManagement from './components/CyclesManagement';
import OnlineUsersBar from './components/OnlineUsersBar';
import { DarkModeProvider } from './components/DarkModeContext';




import apiService, { logout, getCurrentUserDetails } from './apiService';

const theme = createTheme();

const AppContent = ({ authState, setAuthState, handleLogin, handleLogout }) => {
  const [notesVisible, setNotesVisible] = useState(false);
  const [showQuote, setShowQuote] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/social' && authState.isAuthenticated) {
      setShowQuote(Date.now());
    }
  }, [location, authState.isAuthenticated]);

  useEffect(() => {
    const interceptor = apiService.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          handleLogout();
          navigate('/login');
        }
        return Promise.reject(error);
      }
    );

    return () => {
      apiService.interceptors.response.eject(interceptor);
    };
  }, [handleLogout, navigate]);

  const toggleNotes = useCallback(() => {
    setNotesVisible(prev => !prev);
  }, []);

  const handleCloseQuote = () => {
    setShowQuote(false);
  };

  return (
    <div className="App">
      {authState.isAuthenticated && authState.currentUser && (
        <>
          <Navbar 
            logout={handleLogout} 
            currentUser={authState.currentUser}
            fullyAuthenticated={authState.fullyAuthenticated}
          />
          <OnlineUsersBar />
        </>
      )}
      <div style={{ paddingTop: '90px' }}></div>
      <Routes>
        <Route 
          path="/login" 
          element={
            !authState.isAuthenticated ? (
              <Login onLogin={handleLogin} />
            ) : (
              <Navigate to="/leads" replace />
            )
          } 
        />
        <Route 
          path="/social" 
          element={
            authState.isAuthenticated ? (
              <SocialUpdates currentUser={authState.currentUser} />
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
        <Route 
          path="/dashboard" 
          element={
            authState.isAuthenticated ? <Dashboard /> : <Navigate to="/login" replace />
          } 
        />
        <Route 
          path="/leads" 
          element={
            authState.isAuthenticated && authState.currentUser ? (
              <Leads currentUser={authState.currentUser} />
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
       <Route 
  path="/cycles" 
  element={
    authState.isAuthenticated && authState.currentUser?.role === 'administrator' ? (
      <CyclesManagement />
    ) : (
      <Navigate to="/login" replace />
    )
  } 
/>
        <Route 
          path="/tasks" 
          element={
            authState.isAuthenticated ? (
              <TaskList currentUser={authState.currentUser} />
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
        <Route 
          path="/canned-responses" 
          element={
            authState.isAuthenticated ? <CannedResponses /> : <Navigate to="/login" replace />
          } 
        />
        <Route 
          path="/reminders" 
          element={
            authState.isAuthenticated ? (
              <Reminders currentUser={authState.currentUser} />
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
        <Route 
          path="/wiki" 
          element={
            authState.isAuthenticated ? <Wiki /> : <Navigate to="/login" replace />
          } 
        />
        <Route 
          path="/user/:userId" 
          element={
            authState.isAuthenticated ? (
              <UserProfile currentUser={authState.currentUser} />
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
        <Route 
  path="/assignment-rules" 
  element={
    authState.isAuthenticated && authState.currentUser?.role === 'administrator' ? (
      <AssignmentRules />
    ) : (
      <Navigate to="/login" replace />
    )
  } 
/>
        <Route path="/reports" element={<Reports />} />
        <Route path="/" element={<Navigate to="/social" replace />} />
      </Routes>
      {authState.isAuthenticated && authState.currentUser && (
        <>
          <UserNotes 
            visible={notesVisible} 
            onClose={() => setNotesVisible(false)} 
            token={authState.token}
          />
          <GlobalChat />
          <ReminderPopup />
          
          <button
            onClick={toggleNotes}
            style={{
              position: 'fixed',
              right: '0',
              top: '50%',
              transform: 'translateY(-50%) rotate(-90deg)',
              transformOrigin: 'right bottom',
              zIndex: 1000,
              padding: '10px 20px',
              backgroundColor: 'red',
              color: 'white',
              border: 'none',
              borderRadius: '5px 5px 0 0',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: 'bold',
              boxShadow: '-2px 0 5px rgba(0,0,0,0.2)'
            }}
          >
            Notes
          </button>
        </>
      )}
      <QuotePopup visible={showQuote} onClose={handleCloseQuote} />
    </div>
  );
};

const App = () => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    currentUser: null,
    loading: true,
    fullyAuthenticated: false,
    token: null
  });

  const navigate = useNavigate();

  const fetchUserDetails = useCallback(async (token) => {
    try {
      const userDetails = await getCurrentUserDetails();
      setAuthState({
        isAuthenticated: true,
        currentUser: userDetails,
        loading: false,
        fullyAuthenticated: false,
        token: token
      });

      setTimeout(() => {
        setAuthState(prev => ({ ...prev, fullyAuthenticated: true }));
      }, 4000);
    } catch (error) {
      console.error('Error fetching user details:', error);
      localStorage.removeItem('jwtToken');
      setAuthState({
        isAuthenticated: false,
        currentUser: null,
        loading: false,
        fullyAuthenticated: false,
        token: null
      });
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      fetchUserDetails(token);
    } else {
      setAuthState(prev => ({ ...prev, loading: false }));
      navigate('/login');
    }
  }, [fetchUserDetails, navigate]);

  const handleLogin = useCallback((token) => {
    localStorage.setItem('jwtToken', token);
    fetchUserDetails(token);
  }, [fetchUserDetails]);

  const handleLogout = useCallback(() => {
    logout();
    setAuthState({
      isAuthenticated: false,
      currentUser: null,
      loading: false,
      fullyAuthenticated: false,
      token: null
    });
    localStorage.removeItem('jwtToken');
    navigate('/login');
  }, [navigate]);

  if (authState.loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContent 
        authState={authState} 
        setAuthState={setAuthState} 
        handleLogin={handleLogin} 
        handleLogout={handleLogout}
      />
    </ThemeProvider>
  );
};

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;