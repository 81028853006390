// DarkModeContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  // Initialize state from localStorage, falling back to false if not set
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('tableDarkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  // Update localStorage when dark mode changes
  useEffect(() => {
    localStorage.setItem('tableDarkMode', JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(prev => !prev);
  };

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = () => {
  const context = useContext(DarkModeContext);
  if (!context) {
    throw new Error('useDarkMode must be used within a DarkModeProvider');
  }
  return context;
};

export default DarkModeContext;