import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Input, Button, Badge, Avatar, Upload, message, Tooltip, Mentions, Modal,Popconfirm } from 'antd';
import { SendOutlined, MessageOutlined, PictureOutlined, UserOutlined, EditOutlined, DeleteOutlined, CommentOutlined,FileOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment-timezone';
import useSound from 'use-sound';
import notificationSound from './assets/notification.mp3';
import { debounce } from 'lodash';

import { 
  getChatMessages, 
  sendChatMessage, 
  updateChatMessage, 
  deleteChatMessage, 
  markMessagesAsRead, 
  getOnlineUsers, 
  convertToIST,
  updateOnlineStatus,
  getAllUsers,
  getUserDetailsById,
  getUnreadMessageCount,
  uploadMedia, getCurrentUserDetails
} from '../apiService';

const { TextArea } = Input;

const ChatContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: ${props => props.isOpen ? '400px' : '60px'};
  height: ${props => props.isOpen ? '600px' : '60px'};
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  z-index: 1000;
  overflow: hidden;
`;

const ChatHeader = styled.div`
  background-color: #1890ff;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
`;

const ChatBody = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-direction: column-reverse;
`;

const ChatInput = styled.div`
  display: flex;
  padding: 15px;
  background-color: #f0f2f5;
  align-items: center;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.sent ? 'row-reverse' : 'row'};
  align-items: flex-end;
  margin-bottom: 15px;
`;

const MessageContent = styled.div`
  max-width: 70%;
  padding: 10px 15px;
  border-radius: 18px;
  background-color: ${props => props.color || (props.sent ? '#1890ff' : '#f0f2f5')};
  color: ${props => props.sent ? 'white' : 'black'};
  margin: ${props => props.sent ? '0 10px 0 0' : '0 0 0 10px'};
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  position: relative;
`;

const ImageCache = {
  cache: new Map(),
  
  set: function(userId, imageUrl) {
    this.cache.set(userId, {
      url: imageUrl,
      timestamp: Date.now()
    });
  },
  
  get: function(userId) {
    const entry = this.cache.get(userId);
    if (entry && Date.now() - entry.timestamp < 3600000) { // Cache valid for 1 hour
      return entry.url;
    }
    return null;
  }
};

const MessageAvatar = ({ userId, imageUrl, sent }) => {
  const [avatarUrl, setAvatarUrl] = useState(ImageCache.get(userId) || imageUrl);

  useEffect(() => {
    if (imageUrl && !ImageCache.get(userId)) {
      ImageCache.set(userId, imageUrl);
      setAvatarUrl(imageUrl);
    }
  }, [userId, imageUrl]);

  return (
    <Avatar
      src={avatarUrl}
      icon={<UserOutlined />}
      style={{ margin: sent ? '0 0 0 10px' : '0 10px 0 0' }}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = '/default-avatar.png';
      }}
    />
  );
};

const MessageInfo = styled.div`
  font-size: 12px;
  color: #888;
  margin-top: 5px;
  text-align: ${props => props.sent ? 'right' : 'left'};
`;

const DateSeparator = styled.div`
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
  color: #888;
`;

const ReplyPreview = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px;
  margin-bottom: 10px;
  border-left: 3px solid #1890ff;
  font-size: 0.9em;
  border-radius: 4px;
`;

const OnlineUsersList = styled.div`
  background-color: #f9f9f9;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-bottom: 1px solid #eaeaea;
`;

const OnlineUserAvatar = ({ user }) => {
  const [avatarUrl, setAvatarUrl] = useState(ImageCache.get(user.id) || user.profile_image);

  useEffect(() => {
    if (user.profile_image && !ImageCache.get(user.id)) {
      ImageCache.set(user.id, user.profile_image);
      setAvatarUrl(user.profile_image);
    }
  }, [user.id, user.profile_image]);

  return (
    <OnlineUser>
      <Avatar 
        src={avatarUrl}
        icon={<UserOutlined />} 
        size={40}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = '/default-avatar.png';
        }}
      />
      <UserStatus 
        isOnline={moment().diff(moment(user.last_active), 'minutes') < 1} 
      />
    </OnlineUser>
  );
};

const OnlineUser = styled.div`
  position: relative;
`;

const UserStatus = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.isOnline ? '#52c41a' : '#8c8c8c'};
  border: 2px solid white;
`;

const MessageActions = styled.div`
  position: absolute;
  top: -20px;
  ${props => props.sent ? 'left: 0;' : 'right: 0;'}
  display: flex;
  gap: 5px;
  opacity: 0;
  transition: opacity 0.2s;
  background-color: white;
  border-radius: 10px;
  padding: 2px 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);

  ${MessageContent}:hover & {
    opacity: 1;
  }
`;

const ActionIcon = styled.span`
  cursor: pointer;
  color: #888;
  font-size: 14px;

  &:hover {
    color: #1890ff;
  }
`;

const ChatImage = styled.img`
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
`;

const GlobalChat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [unreadCount, setUnreadCount] = useState(0);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);
  const [replyingTo, setReplyingTo] = useState(null);
  const [fileList, setFileList] = useState([]);
  const chatBodyRef = useRef(null);
  const [mentions, setMentions] = useState([]);
  const [imagePreviewVisible, setImagePreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [profileImageCache, setProfileImageCache] = useState({});
  const [userColors, setUserColors] = useState({});
  const [lastReadMessageId, setLastReadMessageId] = useState(null);
  const [play] = useSound(notificationSound);
  const [currentUserId, setCurrentUserId] = useState(null);

  const getRandomLightColor = () => {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, 95%)`;
  };

  const getUserColor = useCallback((userId) => {
    if (!userColors[userId]) {
      setUserColors(prevColors => ({
        ...prevColors,
        [userId]: getRandomLightColor()
      }));
    }
    return userColors[userId];
  }, [userColors]);




 

  // Helper function to check if a message is read by the current user
  const isMessageRead = useCallback((message) => {
    if (!currentUserId || !message.read_by) return false;
    return message.read_by.some(reader => reader.user_id === currentUserId.toString());
  }, [currentUserId]);

  // Modified fetchMessages to properly handle read/unread state
  const fetchMessages = useCallback(async (pageNum = 1, isInitialFetch = false) => {
    if (loading) return;
    
    // Use function form of setLoading to avoid dependency
    setLoading(true);
    
    try {
      const response = await getChatMessages(pageNum);
      if (!Array.isArray(response)) return;
      
      // Use cached user details where possible
      const formattedMessages = await Promise.all(response.map(async (msg) => {
        const cachedUser = profileImageCache[msg.user_id];
        let userDetails;
        
        if (cachedUser && Date.now() - cachedUser.timestamp < 3600000) {
          userDetails = cachedUser;
        } else {
          userDetails = await getUserDetailsById(msg.user_id);
          // Update cache
          setProfileImageCache(prev => ({
            ...prev,
            [msg.user_id]: {
              ...userDetails,
              timestamp: Date.now()
            }
          }));
        }
        
        return {
          ...msg,
          created_at: convertToIST(msg.created_at),
          updated_at: convertToIST(msg.updated_at),
          user_avatar: userDetails.profile_image_url,
          user_name: userDetails.display_name
        };
      }));

      setMessages(prev => {
        if (pageNum === 1) {
          if (!isOpen) {
            // For first page when chat is closed, find all unread messages
            const unreadMessages = formattedMessages.filter(msg => !isMessageRead(msg));
            
            if (isInitialFetch) {
              // On initial fetch, set total unread count
              setUnreadCount(unreadMessages.length);
            } else {
              // For subsequent fetches, check for new messages
              const lastMessageId = prev[0]?.id || '0';
              const newMessages = unreadMessages.filter(msg => 
                parseInt(msg.id) > parseInt(lastMessageId)
              );
              
              if (newMessages.length > 0) {
                setUnreadCount(prevCount => prevCount + newMessages.length);
                play();
                showNotification(newMessages[0]);
              }
            }
          }
          return formattedMessages;
        }
        return [...prev, ...formattedMessages];
      });
      
      setHasMore(formattedMessages.length === 20);
      setPage(pageNum + 1);
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoading(false);
    }
  }, [currentUserId, isOpen, play, profileImageCache]); 

  const getCachedProfileImage = useCallback(async (userId, imageUrl) => {
    const now = Date.now();
    const cachedImage = profileImageCache[userId];
    
    // Return existing cache if valid and less than an hour old
    if (cachedImage && (now - cachedImage.timestamp < 3600000)) {
      return cachedImage.url;
    }
    
    // Simply store the original URL in cache
    // We'll let the img tag handle the loading
    setProfileImageCache(prev => ({
      ...prev,
      [userId]: {
        url: imageUrl,
        timestamp: now
      }
    }));
    
    return imageUrl;
  }, [profileImageCache]);

  const updateOnlineUsersCache = useCallback((users) => {
    users.forEach(user => {
      if (user.profile_image && !ImageCache.get(user.id)) {
        ImageCache.set(user.id, user.profile_image);
      }
    });
  }, []);

  const fetchOnlineUsers = useCallback(async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        console.error('No authentication token found');
        return;
      }

      const response = await fetch('https://it.nilconnect.in/wp-json/ei-crm/v1/chat/online-users', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const users = await response.json();
      
      const formattedUsers = users.map(user => ({
        ...user,
        last_active: convertToIST(user.last_active)
      }));
      
      updateOnlineUsersCache(formattedUsers);
      setOnlineUsers(formattedUsers);
    } catch (error) {
      console.error('Error fetching online users:', error);
    }
  }, [updateOnlineUsersCache]);

  // Keep this effect but modify it to also set currentUser
useEffect(() => {
  const fetchCurrentUser = async () => {
    try {
      const userDetails = await getCurrentUserDetails();
      setCurrentUserId(userDetails.user_id);
      setCurrentUser(userDetails); // Add this line
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  };
  fetchCurrentUser();
}, []);

  // Replace the existing message fetch effect with this
  useEffect(() => {
    if (!currentUserId) return;

    // Initial fetch
    fetchMessages(1, true);
    
    // Set up polling with reasonable interval (30 seconds)
    const pollInterval = setInterval(() => {
      if (!isOpen) {
        fetchMessages(1, false);
      }
    }, 10000);

    return () => clearInterval(pollInterval);
  }, [currentUserId, isOpen]);

const markAllAsRead = useCallback(async () => {
  if (messages.length > 0 && unreadCount > 0 && currentUserId) {
    try {
      const unreadMessageIds = messages
        .filter(msg => !isMessageRead(msg))
        .map(msg => msg.id);
      
      if (unreadMessageIds.length > 0) {
        await markMessagesAsRead(unreadMessageIds);
        
        // Update messages to include current user in read_by
        setMessages(prevMessages => prevMessages.map(msg => {
          if (unreadMessageIds.includes(msg.id)) {
            return {
              ...msg,
              read_by: [
                ...(msg.read_by || []),
                { 
                  user_id: currentUserId.toString(),
                  read_at: new Date().toISOString()
                }
              ]
            };
          }
          return msg;
        }));
        
        setUnreadCount(0);
      }
    } catch (error) {
      console.error('Error marking messages as read:', error);
      message.error('Failed to mark messages as read');
    }
  }
}, [messages, unreadCount, currentUserId, isMessageRead]);

// Add this new effect to handle chat open state
useEffect(() => {
  if (!currentUserId || !isOpen) return;

  // When chat is opened
  fetchOnlineUsers();
  fetchAllUsers();
  markAllAsRead();
  
  // Set up periodic updates while chat is open
  const openChatInterval = setInterval(() => {
    fetchMessages(1, false);
    fetchOnlineUsers();
  }, 10000);

  return () => clearInterval(openChatInterval);
}, [currentUserId, isOpen, fetchOnlineUsers, fetchMessages, markAllAsRead]);



  const showNotification = (latestMessage) => {
    if (Notification.permission === "granted") {
      new Notification("New Chat Message", {
        body: `${latestMessage.user_name}: ${latestMessage.message}`,
        icon: latestMessage.user_avatar
      });
    }
  };

  const uploadMedia = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await uploadMedia(formData);
      return response.data;
    } catch (error) {
      console.error('Error uploading media:', error);
      throw error;
    }
  };

  const sendMessage = async () => {
    if (!newMessage.trim() && fileList.length === 0) return;
    try {
      let mediaId = null;
      let mediaUrl = null;
      let mediaType = null;
      if (fileList.length > 0) {
        const uploadedMedia = await uploadMedia(fileList[0].originFileObj);
        mediaId = uploadedMedia.id;
        mediaUrl = uploadedMedia.url;
        mediaType = uploadedMedia.type;
      }

      const sentMessage = await sendChatMessage(newMessage, mediaId, replyingTo?.id, mentions);
      setMessages(prev => [sentMessage, ...prev]);
      setNewMessage('');
      setFileList([]);
      setReplyingTo(null);
      setMentions([]);
    } catch (error) {
      console.error('Error sending message:', error);
      message.error('Failed to send message');
    }
  };

  const editMessage = async (messageId, newContent) => {
    try {
      const updatedMessage = await updateChatMessage(messageId, newContent);
      setMessages(prev => prev.map(msg => msg.id === messageId ? {...msg, ...updatedMessage} : msg));
      setEditingMessage(null);
    } catch (error) {
      console.error('Error editing message:', error);
      message.error('Failed to edit message');
    }
  };

  const deleteMessage = async (messageId) => {
    try {
      await deleteChatMessage(messageId);
      setMessages(prev => prev.filter(msg => msg.id !== messageId));
      message.success('Message deleted successfully');
    } catch (error) {
      console.error('Error deleting message:', error);
      message.error('Failed to delete message');
    }
  };

  

  

 

  const fetchAllUsers = async () => {
    try {
      const response = await getAllUsers();
      if (Array.isArray(response.users)) {
        setAllUsers(response.users);
      } else {
        console.error('Unexpected response format for getAllUsers:', response);
        setAllUsers([]);
      }
    } catch (error) {
      console.error('Error fetching all users:', error);
      setAllUsers([]);
    }
  };

  const updateStatus = useCallback(async () => {
    try {
      await updateOnlineStatus();
    } catch (error) {
      console.error('Failed to update online status:', error);
    }
  }, []);

  useEffect(() => {
    if (!currentUserId) return;

    updateStatus();
    const statusInterval = setInterval(updateStatus, 60000); // Once per minute
    
    return () => clearInterval(statusInterval);
  }, [currentUserId, updateStatus]);
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.global-chat')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleMentionSelect = (option) => {
    setMentions(prev => [...prev, option.key]);
  };

  const adjustTimestamp = (timestamp) => {
    return moment(timestamp);
  };

  const renderMessageContent = (msg) => (
    <>
      <div>{msg.message}</div>
      {msg.media_url && (
        <div>
          {msg.media_type && (msg.media_type === 'image/webp' || msg.media_type.startsWith('image/')) ? (
            <ChatImage 
              src={msg.media_url} 
              alt="Attached Image" 
              onClick={() => {
                setPreviewImage(msg.media_url);
                setImagePreviewVisible(true);
              }}
            />
          ) : (
            <a href={msg.media_url} target="_blank" rel="noopener noreferrer">
              <FileOutlined /> {msg.media_type || 'Attachment'}
            </a>
          )}
        </div>
      )}
    </>
  );

  const renderMessage = useCallback((msg, prevMsg) => {
    const isSentByCurrentUser = msg.user_id === currentUser?.id;
    const showDateSeparator = !prevMsg || !moment(msg.created_at).isSame(prevMsg.created_at, 'day');
    const adjustedTimestamp = adjustTimestamp(msg.created_at);
    const bubbleColor = getUserColor(msg.user_id);

    return (
      <>
        {showDateSeparator && (
          <DateSeparator>{adjustedTimestamp.format('MMMM D, YYYY')}</DateSeparator>
        )}
        <MessageContainer sent={isSentByCurrentUser}>
          <MessageAvatar 
             userId={msg.user_id}
             imageUrl={msg.user_avatar}
             sent={isSentByCurrentUser}
          />
          <div>
            <MessageContent sent={isSentByCurrentUser} color={bubbleColor}>
              {msg.parent_id !== "0" && (
                <ReplyPreview>
                  Replying to {messages.find(m => m.id === msg.parent_id)?.user_name}: 
                  {messages.find(m => m.id === msg.parent_id)?.message}
                </ReplyPreview>
              )}
              {renderMessageContent(msg)}
              <MessageActions sent={isSentByCurrentUser}>
                <ActionIcon onClick={() => setReplyingTo(msg)}><CommentOutlined /></ActionIcon>
                {isSentByCurrentUser && (
                  <>
                    <ActionIcon onClick={() => setEditingMessage(msg)}><EditOutlined /></ActionIcon>
                    <Popconfirm
                      title="Are you sure you want to delete this message?"
                      onConfirm={() => deleteMessage(msg.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <ActionIcon><DeleteOutlined /></ActionIcon>
                    </Popconfirm>
                  </>
                )}
              </MessageActions>
            </MessageContent>
            <MessageInfo sent={isSentByCurrentUser}>
              {msg.user_name} • {adjustedTimestamp.format('h:mm A')}
              {msg.is_edited && ' (edited)'}
            </MessageInfo>
          </div>
        </MessageContainer>
      </>
    );
  }, [currentUser, profileImageCache, messages, deleteMessage, getUserColor]);

  const renderOnlineUsers = useCallback(() => (
    <OnlineUsersList>
      {onlineUsers.map(user => (
        <Tooltip 
          key={user.id} 
          title={
            <>
              <div>{user.display_name}</div>
              <div>
                {moment().diff(moment(user.last_active), 'minutes') < 5 ? 
                  'Online' : 
                  `Last seen ${moment(user.last_active).fromNow()}`
                }
              </div>
            </>
          }
        >
          <OnlineUserAvatar user={user} />
        </Tooltip>
      ))}
    </OnlineUsersList>
  ), [onlineUsers]);

  const handleOpenChat = useCallback(() => {
    if (loading) return;  // Add this line
    setIsOpen(true);
    markAllAsRead();
  }, [markAllAsRead, loading]);

  return (
    <ChatContainer isOpen={isOpen} className="global-chat">
      <ChatHeader onClick={handleOpenChat}>
        <Badge count={unreadCount}>
          <MessageOutlined style={{ fontSize: '24px', color: 'white' }} />
        </Badge>
        {isOpen && <span>Global Chat</span>}
      </ChatHeader>
      {isOpen && (
        <>
          {renderOnlineUsers()}
          <ChatBody ref={chatBodyRef}>
            {messages.map((msg, index) => renderMessage(msg, messages[index + 1]))}
            {hasMore && (
              <Button onClick={() => fetchMessages(page)} loading={loading}>
                Load More
              </Button>
            )}
          </ChatBody>
          <ChatInput>
            {replyingTo && (
              <ReplyPreview>
                Replying to {replyingTo.user_name}: {replyingTo.message}
                <Button size="small" onClick={() => setReplyingTo(null)}>Cancel</Button>
              </ReplyPreview>
            )}
            {editingMessage ? (
              <TextArea
                value={editingMessage.message}
                onChange={(e) => setEditingMessage({ ...editingMessage, message: e.target.value })}
                onPressEnter={(e) => {
                  if (!e.shiftKey) {
                    e.preventDefault();
                    editMessage(editingMessage.id, editingMessage.message);
                  }
                }}
                autoFocus
              />
            ) : (
              <Mentions
                style={{ width: '100%', marginRight: '10px' }}
                value={newMessage}
                onChange={(value) => setNewMessage(value)}
                onSelect={handleMentionSelect}
                placeholder="Type a message... (Use @ to mention)"
                onPressEnter={(e) => {
                  if (!e.shiftKey) {
                    e.preventDefault();
                    sendMessage();
                  }
                }}
              >
                {allUsers.map(user => (
                  <Mentions.Option key={user.id} value={user.display_name}>
                    {user.display_name}
                  </Mentions.Option>
                ))}
              </Mentions>
            )}
            <Upload
              fileList={fileList}
              onChange={({ fileList }) => setFileList(fileList)}
              beforeUpload={(file) => {
                if (file.type.startsWith('image/')) {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    setFileList([{ originFileObj: file, url: e.target.result }]);
                  };
                  reader.readAsDataURL(file);
                } else {
                  setFileList([{ originFileObj: file, name: file.name }]);
                }
                return false;
              }}
              showUploadList={false}
            >
              <Button icon={<PictureOutlined />} />
            </Upload>
            <Button 
              type="primary" 
              icon={editingMessage ? <EditOutlined /> : <SendOutlined />} 
              onClick={() => editingMessage ? editMessage(editingMessage.id, editingMessage.message) : sendMessage()}
            />
          </ChatInput>
          {fileList.length > 0 && fileList[0].url && (
            <div style={{ padding: '10px', backgroundColor: '#f0f2f5' }}>
              <img src={fileList[0].url} alt="Upload preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
            </div>
          )}
        </>
      )}
      <Modal
        visible={imagePreviewVisible}
        footer={null}
        onCancel={() => setImagePreviewVisible(false)}
        width="80%"
        style={{ top: 20 }}
      >
        <img 
          alt="preview" 
          style={{ 
            width: '100%', 
            height: 'auto', 
            maxHeight: '80vh', 
            objectFit: 'contain' 
          }} 
          src={previewImage} 
        />
      </Modal>
    </ChatContainer>
  );
};

export default GlobalChat;