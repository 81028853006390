import React, { useState, useEffect, useCallback } from 'react';
import { 
  Table, 
  Button, 
  Modal, 
  Form, 
  Input, 
  Select, 
  message, 
  Space, 
  Card,
  Popconfirm,
  Typography,
  Tag,
  Tooltip,
  Switch, 
  Alert
} from 'antd';
import { 
  PlusOutlined, 
  EditOutlined, 
  DeleteOutlined,
  ArrowRightOutlined,
  InfoCircleOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import { 
  getAutoReassignRules, 
  createAutoReassignRule, 
  updateAutoReassignRule,
  deleteAutoReassignRule,
  toggleAutoReassignRule,
  getUserDetailsById,
  getAllUsers
} from '../apiService';

const { Option } = Select;
const { Title, Text } = Typography;

const AutoReassignmentRules = () => {
  const [rules, setRules] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingRule, setEditingRule] = useState(null);
  const [userNames, setUserNames] = useState({});
  const [form] = Form.useForm();

  const fetchUserName = useCallback(async (userId) => {
    if (!userNames[userId] && userId) {
      try {
        const userDetails = await getUserDetailsById(userId);
        setUserNames(prev => ({
          ...prev,
          [userId]: userDetails.display_name
        }));
      } catch (error) {
        console.error(`Error fetching user details for ID ${userId}:`, error);
      }
    }
  }, [userNames]);

  const fetchRules = async () => {
    setLoading(true);
    try {
      const data = await getAutoReassignRules();
      setRules(data.map(rule => ({
        ...rule,
        key: rule.id
      })));
      
      // Fetch names for all coordinators in rules
      data.forEach(rule => {
        fetchUserName(rule.initial_coordinator.id);
        rule.sequence.forEach(coord => fetchUserName(coord.id));
      });
    } catch (error) {
      message.error('Failed to fetch auto reassignment rules');
    }
    setLoading(false);
  };

  const fetchCoordinators = async () => {
    try {
      const response = await getAllUsers();
      const filteredCoordinators = response.users.filter(user => 
        user.role === 'crm_executive' || user.role === 'administrator'
      );
      setCoordinators(filteredCoordinators);
    } catch (error) {
      message.error('Failed to fetch coordinators');
    }
  };

  useEffect(() => {
    fetchRules();
    fetchCoordinators();
  }, []);

  const handleCreateOrUpdate = async (values) => {
    try {
      const payload = {
        name: values.name,
        initial_coordinator_id: values.initial_coordinator_id,
        sequence: values.sequence
      };

      if (editingRule) {
        await updateAutoReassignRule(editingRule.id, payload);
        message.success('Auto reassignment rule updated successfully');
      } else {
        await createAutoReassignRule(payload);
        message.success('Auto reassignment rule created successfully');
      }
      setModalVisible(false);
      form.resetFields();
      setEditingRule(null);
      fetchRules();
    } catch (error) {
      message.error('Failed to save rule');
    }
  };

  const handleDelete = async (ruleId) => {
    try {
      await deleteAutoReassignRule(ruleId);
      message.success('Auto reassignment rule deleted successfully');
      fetchRules();
    } catch (error) {
      message.error('Failed to delete rule');
    }
  };

  const handleToggle = async (ruleId, active) => {
    try {
      await toggleAutoReassignRule(ruleId, active);
      message.success(`Rule ${active ? 'activated' : 'deactivated'} successfully`);
      fetchRules();
    } catch (error) {
      message.error('Failed to toggle rule');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (text) => <Title level={5}>{text}</Title>
    },
    {
      title: 'Initial Coordinator',
      dataIndex: 'initial_coordinator',
      key: 'initial_coordinator',
      width: '15%',
      render: (coordinator) => (
        <Tag color="green">
          {userNames[coordinator.id] || 'Loading...'}
        </Tag>
      )
    },
    {
      title: (
        <Space>
          <span>Reassignment Sequence</span>
          <Tooltip title="After 6 hours of inactivity, leads will be reassigned following this sequence">
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'sequence',
      key: 'sequence',
      width: '35%',
      render: (sequence) => (
        <Space wrap>
          {sequence.map((coord, index) => (
            <React.Fragment key={coord.id}>
              <Tag color="blue">
                {userNames[coord.id] || 'Loading...'}
              </Tag>
              {index < sequence.length - 1 && (
                <>
                  <ClockCircleOutlined style={{ color: '#1890ff' }} />
                  <Text type="secondary">6h</Text>
                  <ArrowRightOutlined style={{ color: '#1890ff' }} />
                </>
              )}
            </React.Fragment>
          ))}
        </Space>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'status',
      width: '15%',
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={(checked) => handleToggle(record.id, checked)}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '15%',
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit Rule">
            <Button 
              icon={<EditOutlined />}
              type="primary"
              ghost
              onClick={() => {
                setEditingRule(record);
                form.setFieldsValue({
                  name: record.name,
                  initial_coordinator_id: record.initial_coordinator.id,
                  sequence: record.sequence.map(coord => coord.id)
                });
                setModalVisible(true);
              }}
            />
          </Tooltip>
          <Popconfirm
            title="Delete Rule"
            description="Are you sure you want to delete this auto reassignment rule?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ danger: true }}
          >
            <Tooltip title="Delete Rule">
              <Button icon={<DeleteOutlined />} danger />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Card
      title={<Title level={3}>Auto Reassignment Rules</Title>}
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setEditingRule(null);
            form.resetFields();
            setModalVisible(true);
          }}
        >
          Create New Rule
        </Button>
      }
    >
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        

        <Table 
          columns={columns} 
          dataSource={rules}
          loading={loading}
          pagination={false}
        />
      </Space>

      <Modal
        title={editingRule ? 'Edit Auto Reassignment Rule' : 'Create Auto Reassignment Rule'}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingRule(null);
          form.resetFields();
        }}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleCreateOrUpdate}
        >
          <Form.Item
            name="name"
            label="Rule Name"
            rules={[{ required: true, message: 'Please enter rule name' }]}
          >
            <Input placeholder="Enter rule name" />
          </Form.Item>

          <Form.Item
            name="initial_coordinator_id"
            label={
              <Space>
                <span>Initial Coordinator</span>
                <Tooltip title="The coordinator who initially receives the leads">
                  <InfoCircleOutlined />
                </Tooltip>
              </Space>
            }
            rules={[{ required: true, message: 'Please select initial coordinator' }]}
          >
            <Select
              placeholder="Select initial coordinator"
              showSearch
              filterOption={(input, option) =>
                option?.children?.props?.children?.[0].toLowerCase().includes(input.toLowerCase())
              }
            >
              {coordinators.map(coordinator => (
                <Option key={coordinator.id} value={coordinator.id}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{coordinator.display_name}</span>
                    <Text type="secondary">{coordinator.role === 'administrator' ? 'Admin' : 'Coordinator'}</Text>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="sequence"
            label={
              <Space>
                <span>Reassignment Sequence</span>
                <Tooltip title="Select coordinators in the order they should receive leads after inactivity">
                  <InfoCircleOutlined />
                </Tooltip>
              </Space>
            }
            rules={[{ required: true, message: 'Please select sequence coordinators' }]}
          >
            <Select
              mode="multiple"
              placeholder="Select coordinators in sequence order"
              showSearch
              filterOption={(input, option) =>
                option?.children?.props?.children?.[0].toLowerCase().includes(input.toLowerCase())
              }
            >
              {coordinators.map(coordinator => (
                <Option key={coordinator.id} value={coordinator.id}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{coordinator.display_name}</span>
                    <Text type="secondary">{coordinator.role === 'administrator' ? 'Admin' : 'Coordinator'}</Text>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.List name="sequence_preview">
            {() => {
              const initialCoordinator = form.getFieldValue('initial_coordinator_id');
              const sequence = form.getFieldValue('sequence');
              return (
                <div style={{ marginBottom: '24px', padding: '16px', background: '#f5f5f5', borderRadius: '8px' }}>
                  <Title level={5}>Rule Preview:</Title>
                  <div style={{ marginTop: '8px' }}>
                    {initialCoordinator && (
                      <Tag color="green">
                        {coordinators.find(c => c.id === initialCoordinator)?.display_name || 'Unknown'}
                      </Tag>
                    )}
                    {initialCoordinator && sequence?.length > 0 && (
                      <>
                        <ClockCircleOutlined style={{ margin: '0 8px', color: '#1890ff' }} />
                        <Text type="secondary">6h</Text>
                        <ArrowRightOutlined style={{ margin: '0 8px', color: '#1890ff' }} />
                      </>
                    )}
                    {sequence?.map((id, index) => (
                      <React.Fragment key={id}>
                        <Tag color="blue">
                          {coordinators.find(c => c.id === id)?.display_name || 'Unknown'}
                        </Tag>
                        {index < sequence.length - 1 && (
                          <>
                            <ClockCircleOutlined style={{ margin: '0 8px', color: '#1890ff' }} />
                            <Text type="secondary">6h</Text>
                            <ArrowRightOutlined style={{ margin: '0 8px', color: '#1890ff' }} />
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                  <Text type="secondary" style={{ marginTop: '8px', display: 'block' }}>
                    Leads will stop at the last coordinator if there's no activity
                  </Text>
                </div>
              );
            }}
          </Form.List>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {editingRule ? 'Update Rule' : 'Create Rule'}
              </Button>
              <Button onClick={() => {
                setModalVisible(false);
                setEditingRule(null);
                form.resetFields();
              }}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default AutoReassignmentRules;