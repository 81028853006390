import React, { useState, useEffect } from 'react';

const AnimatedTooltip = ({ onClose }) => {
  const [showTooltip, setShowTooltip] = useState(true);
  const [visible, setVisible] = useState(true);
  
  useEffect(() => {
    console.log("AnimatedTooltip mounted");
    const animationInterval = setInterval(() => {
      setVisible(prev => !prev);
    }, 1000); // Reduced from 2000ms to 1000ms for faster animation
    
    const hideTimer = setTimeout(() => {
      console.log("Hiding tooltip");
      setShowTooltip(false);
      onClose?.();
    }, 10000);
    
    return () => {
      clearInterval(animationInterval);
      clearTimeout(hideTimer);
    };
  }, [onClose]);

  if (!showTooltip) {
    console.log("Not showing tooltip");
    return null;
  }
  
  console.log("Rendering tooltip");
  
  return (
    <div className="fixed pointer-events-none"
         style={{ 
           top: '20px',
           left: '220px',
           zIndex: 99999
         }}>
      <div className={`
        bg-blue-600 text-white px-6 py-3 rounded-xl
        shadow-xl pointer-events-auto cursor-pointer
        transform transition-all duration-300 ease-in-out
        ${visible ? 'translate-y-0' : 'translate-y-2'} 
      `}
      onClick={() => {
        setShowTooltip(false);
        onClose?.();
      }}>
        <div className="text-sm font-medium whitespace-nowrap">
          Send private messages
        </div>
        {/* Triangle pointer positioned on the left */}
        <div className="
          absolute -bottom-2 left-4
          w-0 h-0 
          border-l-[8px] border-l-transparent
          border-r-[8px] border-r-transparent
          border-t-[8px] border-t-blue-600
        "/>
      </div>
    </div>
  );
};

export default AnimatedTooltip;